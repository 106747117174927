import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { graphql } from 'gatsby'


class PrivacyPage extends Component {
  render() {  

return (
  <Layout page={"home"}>
  <div className="main">
  <div class="home-main">


    
      <h1 className="title">Gebruiksvoorwaarden</h1>
	  <p className="text">
	  Deze pagina beschrijft de gebruiksvoorwaarden van de diensten verlend via deze website.
	  </p>

	  <h3 className="text">AARD VAN DE DIENSTEN</h3>
	

	  <p className="text">
	  Eens u geabonneerd bent via de website hebt u het recht om de audiobestanden die deze lessenreeks omvat te beluisteren. Dit betekent dat, eens u toegang hebt deze audiobestanden, deze zijn ontzegeld en u het recht heeft deze te gebruiken gedurende de periode dat u bent geabonneerd. 
	  </p>
	  
	  <h3 className="text">TERUGKEERBELEID</h3>
	 
	 <p className="text">	
	 Gezien een groot deel van de waarde van de dienst vervat is in een eerste beluistering en beleving van de bestanden kan er geen volledige terugbetaling worden gedaan bij een vroegtijdige annulatie. 
	 De diensten op de website hebben echter het doel om u te helpen. Indien u van oordeel bent dat de diensten niet voldoen aan uw verwachtingen, neem dan contact op via onderstaande coördinaten. Gelieve duidelijk te beschrijven welke uw klachten zijn zodat we kunnen komen tot een passende oplossing.
     </p>
	

<h3 className="text">
CONTACTEER ONS
</h3>

<p className="text">
  Chris Lenaerts, Koning Leopold II Laan, 99 - B 401, 9000 Gent, België
</p> 

<p className="text">
  Ondernemingsnummer : 0771798514
</p> 

<p className="text">
  Email : revalidatie.lenaerts@telenet.be
</p> 

<p className="text">
  Telefoon : +32 495 355 260
</p> 

  </div>
  </div>
  
  </Layout>

)
  }
}
export default PrivacyPage

